<script setup>
import { ref, onMounted, defineProps } from "vue";
const props = defineProps(['isDarkMode']);
const direction = ref()
const updateDirection = () => {
    direction.value = "ltr"; 
    document.querySelector("html").setAttribute('dir', direction.value);
    document.getElementsByClassName("navbar-nav")[0].style.display = "none"
    document.getElementsByClassName("two_links")[0].style.display = "none"

};
onMounted(updateDirection);
</script>

<template>
    <div class="container terms">
        <h1>Terms of Use</h1>
        <p><strong>Last Updated: September 11, 2024</strong></p>

        <p>Welcome to becuriousjourney! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using our website, you agree to these Terms of Use. If you do not agree, please do not use the website.</p>

        <h2>2. Use of the Website</h2>
        <ul>
            <li>You may use the website for lawful purposes only.</li>
            <li>You agree not to use the website in a way that could harm, disable, overburden, or impair any of our services.</li>
        </ul>

        <h2>3. Intellectual Property</h2>
        <p>All content on this website, including text and graphics, is the property of becuriousjourney and is protected by copyright laws. Unauthorized use of any content may violate copyright, trademark, and other laws.</p>

        <h2>4. Disclaimers</h2>
        <ul>
            <li>The information provided on this website is for general informational purposes only. We do not guarantee the accuracy, completeness, or usefulness of any information.</li>
            <li>Any reliance you place on such information is strictly at your own risk.</li>
        </ul>

        <h2>5. Limitation of Liability</h2>
        <p>becuriousjourney is not responsible for any damages arising from your use of the website. The website is provided on an "as-is" basis without warranties of any kind.</p>

        <h2>6. Changes to the Terms</h2>
        <p>We may modify these Terms of Use at any time. Changes will be effective immediately upon posting on the website.</p>

        <h2>7. Contact Information</h2>
        
        <p>If you have any questions about these Terms of Use, please contact us at <strong><a  href="mailto:becuriousjourney@gmail.com" :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">becuriousjourney@gmail.com</a></strong> .</p>
    </div>
</template>
<style>
        .terms .container {
            width: 80%;
            margin: 20px auto;
            padding: 40px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        .terms h1 {
            text-align: center;
            margin-bottom: 40px;
        }
        .terms h2 {
            margin-top: 20px;
            font-size: 1.4rem;
        }
        .terms p {
            margin: 15px 0;
        }
        .terms ul {
            list-style-type: disc;
            margin-left: 20px;
        }
        .terms a {
            text-decoration: none;
        }
        .terms a:hover {
            text-decoration: underline;
        }
</style>